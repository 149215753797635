import { format } from 'date-fns';
import mobileLogStore from 'store/mobileLogStore';
import ua from 'utils/uaParser';

import packageJson from '../../package.json';
import { devLog } from './dev';

type TCommon = {
  token: string;
  local_time?: string; // yyyyMMddHHmmssSSS
  ip?: string;
  log_version: string;
  logbox_version: string;
  app_version: string;
  session_id: string;
  sub_session_id?: string;
};

type TEnv = {
  os_name: string;
  os_version: string;
  resolution: string;
  screen_width: string;
  screen_height: string;
  carrier_name: string;
  network_type: string;
  language_code?: string;
  manufacturer: string;
  device_id?: string;
  device_model: string;
  browser_name: string;
  browser_version: string;
  referrer: string;
  url: string;
  document_title: string;
};

type TMeta = {
  app_id: string;
  service_id: string;
  page_type: string;
  page_id: string;
  action_id?: string;
  section_id?: 'gnb' | 'search_list';
};

type TInitParams = {
  serviceId: string;
  accessKey: string;
  sessionId?: string;
  sessionKey: string;
  title: string;
  packageVersion: string;
  isProdEnv: boolean;
  carrierName?: string;
  deviceId?: string;
  userKey?: string;
  referrer?: string;
};

enum ETlaType {
  EVENT = 'event',
  APP = 'app',
  EXPOSE = 'expose',
}

enum ETlaEnv {
  STAGE = 'STAGE',
  PROD = 'PROD',
}

const TlaUrl = {
  [ETlaEnv.STAGE]: 'https://tla-stg.tmap.co.kr:8988/api/v1/log/',
  [ETlaEnv.PROD]: 'https://tla.tmap.co.kr:8988/api/v1/log/',
};

const TlaToken = {
  [ETlaEnv.STAGE]: '3AF19605D63E',
  [ETlaEnv.PROD]: 'F5CD39ECE530',
};

class TlaLogger {
  private isProdEnv?: boolean;
  private baseCommon?: TCommon;
  private baseMeta?: TMeta;
  private baseEnv?: TEnv;
  private baseCustom: Record<string, any>;

  init = (props: TInitParams) => {
    this.isProdEnv = props.isProdEnv;
    this.baseCommon = {
      token: TlaToken[props?.isProdEnv ? ETlaEnv.PROD : ETlaEnv.STAGE],
      log_version: '1.0.0',
      logbox_version: '1.0.3',
      app_version: ua.tmapAppVersion,
      session_id: props.sessionId || '',
      sub_session_id: props.sessionKey || '',
      ip: '',
    };

    this.baseMeta = {
      app_id: 'tmap',
      service_id: props.serviceId,
      page_type: 'WEB',
      page_id: '',
    };

    this.baseEnv = {
      document_title: props?.title || '',
      url: window.location.href,
      referrer: props?.referrer || '',
      device_id: props?.deviceId || '',
      device_model: ua.device.model || '',
      os_name: ua.os.name || '',
      os_version: ua.os.version || '',
      browser_name: ua.browser.name || '',
      browser_version: ua.browser.version || '',
      manufacturer: ua.device.vendor || '',
      language_code: 'KR',
      resolution: `${document.documentElement.clientWidth || ''}*${
        document.documentElement.clientHeight || ''
      }`,
      screen_width: `${document.documentElement.clientWidth || ''}`,
      screen_height: `${document.documentElement.clientHeight || ''}`,
      network_type: window.navigator['connection']?.type || '',
      carrier_name: props?.carrierName || '',
    };

    this.baseCustom = {
      pkg_version: packageJson.version || '',
      euk: props?.userKey || '',
    };
  };

  updatePageId = (pageId: string) => {
    if (this.baseMeta) {
      this.baseMeta.page_id = pageId;
    }
  };

  sendApp = (action: string, custom: Record<string, any> = {}) => {
    const nowLogData = this.createLogData({ type: ETlaType.APP, custom, actionId: action });

    this.sendLog(this.getTlaURI(ETlaType.APP, nowLogData));
  };

  sendExpose = (custom: Record<string, any> = {}) => {
    const nowLogData = this.createLogData({ type: ETlaType.EXPOSE, custom });

    this.sendLog(this.getTlaURI(ETlaType.EXPOSE, nowLogData));
  };

  sendEvent = (action: string, custom: Record<string, any> = {}) => {
    const nowLogData = this.createLogData({ type: ETlaType.EVENT, custom, actionId: action });

    this.sendLog(this.getTlaURI(ETlaType.EVENT, nowLogData));
  };

  private createLogData = (params: {
    type: ETlaType;
    actionId?: string;
    custom: Record<string, any>;
  }) => {
    const timestamp = Date.now();
    const timestampFormat = format(timestamp, 'yyyyMMddHHmmssSSS');

    const logData: Record<string, any> = {
      common: {
        ...(this.baseCommon || {}),
        local_time: timestampFormat,
      },
      env: this.baseEnv || {},
      custom: {
        ...(this.baseCustom || {}),
        timestamp: timestamp.toString(),
        ...params.custom,
      },
    };

    let meta: Record<string, any> = {};

    // https://tmobi.atlassian.net/wiki/spaces/TMAPSCH/pages/43496460/02.+App+Log
    if (params.type === ETlaType.APP) {
      meta = {
        app_id: this.baseMeta?.app_id,
        service_id: this.baseMeta?.service_id,
        action_id: params.actionId,
      };
    }

    // https://tmobi.atlassian.net/wiki/spaces/TMAPSCH/pages/43496332/01.+Event+Log
    if (params.type === ETlaType.EVENT) {
      meta = {
        ...(this.baseMeta || {}),
        action_id: params.actionId,
      };
    }

    // https://tmobi.atlassian.net/wiki/spaces/TMAPSCH/pages/43496482/03.+Expose+Log
    if (params.type === ETlaType.EXPOSE) {
      meta = {
        ...(this.baseMeta || {}),
        action_id: '',
      };
    }

    logData.meta = meta;

    return logData;
  };

  private sendLog = (url: string) => {
    const img = new Image(1, 1);

    img.onload = () => {
      img.remove();
      if (!this.isProdEnv) {
        devLog(`[TLA Send] ${url}`);
        mobileLogStore.addLog({
          type: 'MSG',
          title: `[TLA Send] ${url}`,
        });
      }
    };
    img.onerror = () => {
      img.remove();
      if (!this.isProdEnv) {
        devLog(`[TLA Error] ${url}`);
        mobileLogStore.addLog({
          type: 'MSG',
          title: `[TLA Error] ${url}`,
        });
      }
    };
    img.src = url;
  };

  private getTlaURI = (type: ETlaType, data: any, encode: boolean = true) => {
    devLog(`[TLA origin] ${type}`, data.meta.action_id, data.custom, data);

    const stringifyData = JSON.stringify(data);
    const isProdEnv = this.isProdEnv;
    const baseUrl = TlaUrl[isProdEnv ? ETlaEnv.PROD : ETlaEnv.STAGE];
    const queryData = encode ? encodeURIComponent(stringifyData) : stringifyData;

    return `${baseUrl}${type}?data=${queryData}`;
  };
}

const tlaLogger = new TlaLogger();

export default tlaLogger;
