import { TLog } from 'store/mobileLogStore';
import s from 'styles/components/dev/LogList.module.scss';

import LogItem from './LogItem';

type TProps = {
  logs: TLog[];
};

const LogList = ({ logs }: TProps) => {
  return (
    <ul className={s.wrapper}>
      {logs.map((log) => (
        <LogItem
          log={log}
          key={`${log.title}-${log.timestamp.getTime()}`}
        />
      ))}
    </ul>
  );
};

export default LogList;
