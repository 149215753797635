import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import s from 'styles/components/tmds/TmdsChip.module.scss';

type TProps = {
  onClick?: () => void;
  selected?: boolean;
} & PropsWithChildren;

const TmdsChip = ({ children, onClick, selected }: TProps) => {
  return (
    <button
      className={classNames(s.wrapper, {
        [s.selected]: selected,
      })}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default TmdsChip;
