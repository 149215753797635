import { ReactNode } from 'react';
import s from 'styles/components/Tag.module.scss';

type TProps = {
  text: ReactNode;
};

const Tag = ({ text }: TProps) => {
  return (
    <div className={s.wrap}>
      <span className={s.text}>{text}</span>
    </div>
  );
};

export default Tag;
