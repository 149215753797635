import classNames from 'classnames';
import Tag from 'component/Tag';
import { COMPANY_ICON_MAP, COMPANY_NAME_MAP } from 'constant/Scooter';
import usePromotion from 'hooks/usePromotion';
import { ReactComponent as CheckIconMedium } from 'resource/images/Check_Icon_Medium.svg';
import { ReactComponent as IcoBikeChip } from 'resource/images/bi_chip_bike.svg';
import s from 'styles/components/pmMain/PmCompanyItem.module.scss';
import { ECompanyType, EVehicleType } from 'types/App';

type TProps = {
  company?: ECompanyType;
  groupList?: ECompanyType[];
  isSelected: boolean;
  text?: string;
  onClick?: (e) => void;
  selectType?: EVehicleType;
};

const PmCompanyItem = ({
  company,
  text,
  isSelected,
  groupList = [],
  selectType,
  onClick,
}: TProps) => {
  const { currentPromotion } = usePromotion();

  return (
    <li
      className={classNames(s.item, {
        [s.is_selected]: isSelected,
      })}
      onClick={onClick}
      data-cy={company || 'ALL'}
    >
      {!company ? (
        <>
          <span className={s.group}>
            {selectType === EVehicleType.BIKE && (
              <i className={s.icon}>
                <IcoBikeChip />
              </i>
            )}
            {(groupList || []).map((v) => (
              <i
                className={s.icon}
                key={`group-${v}`}
              >
                {COMPANY_ICON_MAP[v]}
              </i>
            ))}
          </span>
          <span className={s.name}>{text}</span>
        </>
      ) : (
        <>
          <i className={s.icon}>{COMPANY_ICON_MAP[company]}</i>
          <span className={s.name}>{COMPANY_NAME_MAP[company]}</span>

          {currentPromotion && company === ECompanyType.TMAP && (
            <div className={s.new}>
              <Tag text={<div className={s.event_text}>이벤트</div>} />
            </div>
          )}
        </>
      )}
      {isSelected && <CheckIconMedium className={s.icon_check} />}
    </li>
  );
};
export default PmCompanyItem;
