import { ReactNode } from 'react';
import { isInTime } from 'utils/date';

import Store from './base';
import { localStorageStore, sessionStorageStore } from './storageStore';

type Promotion = {
  key: string;
  startDate: string;
  endDate: string;
  text: ReactNode;
  checkFn?: () => Promise<boolean>;
};

type State = {
  promotions: Promotion[];
  currentPromotion?: Promotion;
};

const promotions: Promotion[] = [
  {
    key: 'PLACE',
    startDate: '2024-08-01 00:00',
    endDate: '2024-12-31 23:59',
    text: '혜택 적용 중',
    checkFn: async () => true,
  },
  {
    key: 'DRIVER_2',
    startDate: '2024-08-01 00:00',
    endDate: '2024-09-30 23:59',
    text: '대리기사 적용중',
    checkFn: async () => true,
  },
];

class PromotionStore extends Store<State> {
  constructor() {
    super({ promotions });

    promotions.forEach((it) => {
      sessionStorageStore.remove(it.key);
    });
  }

  joinPromotion = (key: string, userKey: string) => {
    const promotion = this.state.promotions.find((it) => it.key === key);
    if (!promotion) return;

    if (isInTime(promotion.startDate, promotion.endDate)) {
      localStorageStore.setValue(key, userKey);
    }
  };

  setCurrentPromotion = (key: string, userKey: string) => {
    const promotion = this.state.promotions.find((it) => it.key === key);
    if (!promotion) return;

    if (isInTime(promotion.startDate, promotion.endDate)) {
      sessionStorageStore.setValue(key, userKey);
      localStorageStore.setValue(key, userKey);

      this.updateState({
        currentPromotion: promotion,
      });
    }
  };

  getPromotionByPriority = async (userKey: string) => {
    for (let i = 0; i < this.state.promotions.length; i++) {
      const it = this.state.promotions[i];
      if (!isInTime(it.startDate, it.endDate)) continue;

      const value = localStorage.getItem(it.key);
      if (!value?.includes(userKey)) {
        continue;
      }

      try {
        const result = await it.checkFn?.();
        if (result) {
          return it;
        }
      } catch {
        continue;
      }
    }
  };

  clearAllPromotion = () => {
    this.state.promotions.forEach((it) => {
      sessionStorageStore.remove(it.key);
      localStorageStore.remove(it.key);
    });
  };

  completePromotion = (key: string) => {
    sessionStorageStore.remove(key);
    localStorageStore.remove(key);
    this.updateState({
      currentPromotion: undefined,
    });
  };
}

const promotionStore = new PromotionStore();

export default promotionStore;
