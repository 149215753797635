import { useCallback } from 'react';

import usePromotion from './usePromotion';
import useStorageStore from './useStorageStore';

/**
 * 메인 헤더의 팝오버 노출 여부를 정하는 hooks
 * 다양한 조건으로 보이지 말지를 정할 확률이 높아져서 따로 분리함
 * TODO: 각 흩어져있는 MAIN_POPOVER 닫는 로직들을 이 hook의 hide 로직을 사용하도록 수정
 */
const useMainHeaderPopover = () => {
  const { currentPromotion } = usePromotion();
  const [forceHide, setForceHide] = useStorageStore<boolean>('FORCE_HIDE', undefined, true);

  const hide = useCallback(() => {
    setForceHide(true);
  }, [setForceHide]);

  const show = useCallback(() => {
    setForceHide(false);
  }, [setForceHide]);

  return { isVisible: currentPromotion && !forceHide, hide, show, text: currentPromotion?.text };
};

export default useMainHeaderPopover;
