import { isProdEnv } from 'constant/Env';

import Store from './base';

export enum ELogStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export type TRequest = {
  body?: Record<string, any>;
  query?: Record<string, string | number>;
};

export type TResponse = Record<string, any>;

export type TLogType = 'API' | 'MSG' | 'ERR' | 'DEB';

export type TLog = {
  key?: string;
  title: string;
  timestamp: Date;
  message?: string | Object;
  request?: TRequest;
  response?: TResponse;
  status?: ELogStatus;
  type?: TLogType;
};

class MobileLogStore extends Store<TLog[]> {
  constructor() {
    super([]);
  }

  addApiLog = (log: Omit<TLog, 'message' | 'status' | 'response' | 'timestamp'>) => {
    if (isProdEnv) {
      return;
    }

    this.setState([
      ...this.state,
      { ...log, status: ELogStatus.PENDING, type: 'API', timestamp: new Date() },
    ]);
  };

  updateApiLog = (key: string, status: ELogStatus, response: TResponse) => {
    const index = this.state.findIndex((it) => it.key === key);
    const targetLog = this.state[index];
    if (targetLog?.type === 'API') {
      targetLog.status = status;
      targetLog.response = response;
      this.setState([...this.state]);
    }
  };

  addLog = (log: Omit<TLog, 'status' | 'timestamp'>) => {
    if (isProdEnv) {
      return;
    }

    this.setState([...this.state, { ...log, type: log.type || 'MSG', timestamp: new Date() }]);
  };
}

const mobileLogStore = new MobileLogStore();

export default mobileLogStore;
