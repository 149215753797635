import { datadogLogs } from '@datadog/browser-logs';
import axios, { AxiosRequestConfig } from 'axios';
import { EApiDataCode, NETWORK_TIMEOUT } from 'constant/Api';
import { isProdEnv } from 'constant/Env';
import { API_HOST } from 'constant/Path';
import qs from 'query-string';
import mobileLogStore, { ELogStatus } from 'store/mobileLogStore';
import { EApiResponseCode } from 'types/Api';
import { v4 } from 'uuid';

import tmapWrapper from './wrappers/TMapWrapper';

const fetcher = axios.create({
  baseURL: API_HOST,
  timeout: NETWORK_TIMEOUT,
  headers: {
    crossDomain: true,
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params) => {
    // axios  {A: ['b', 'c']} to A[]=b&A[]=c
    // qs     {A: ['b', 'c']} to A=b&A=c
    return qs.stringify(params);
  },
});

fetcher.interceptors.response.use(
  (res) => {
    const requestKey = res.config.headers.requestKey;
    const resultCode = `${(res.status === 200 && res?.data?.code) || res.status}` as EApiDataCode;

    const originAccessKey = res.config.headers?.AccessKey;
    const newAccessKey = res.headers.AccessKey;

    if (newAccessKey && originAccessKey !== newAccessKey) {
      tmapWrapper.updateAccessKey(newAccessKey);
      datadogLogs.logger.info('[Update AccessKey]', { origin: originAccessKey, new: newAccessKey });
    }

    if (
      [EApiDataCode.API_ERROR, EApiDataCode.API_ERROR2, EApiDataCode.SERVER_ERROR].includes(
        resultCode
      )
    ) {
      const errorObject = new Error(res.data.message);

      errorObject['customCode'] = resultCode;
      !isProdEnv && mobileLogStore.updateApiLog(requestKey, ELogStatus.FAILURE, errorObject);

      throw errorObject;
    }

    !isProdEnv && mobileLogStore.updateApiLog(requestKey, ELogStatus.SUCCESS, res);
    return res;
  },
  (error) => {
    const requestKey = error.response?.config?.headers?.requestKey;
    !isProdEnv && mobileLogStore.updateApiLog(requestKey, ELogStatus.FAILURE, error.response?.data);
    error['customCode'] = EApiDataCode.GENERAL_FAIL;

    return Promise.reject(error);
  }
);

const mobileLogInterceptor = (req: AxiosRequestConfig) => {
  if (!isProdEnv) {
    const uuidKey = v4();
    mobileLogStore.addApiLog({
      key: uuidKey,
      title: `${req.url} (${req.method})`,
      request: {
        query: req.params,
        body: req.data,
      },
    });
    req.headers = {
      ...req.headers,
      requestKey: uuidKey,
    };
  }
  return req;
};

fetcher.interceptors.request.use(mobileLogInterceptor);

export const injectUserKey = (UserKey: string) => {
  fetcher.interceptors.request.use((req) => {
    req.headers = {
      ...req.headers,
      UserKey,
    };
    return req;
  });
};

const parseResult = (res) => {
  if (`${res.data.code}` === EApiResponseCode.SUCCESS) {
    return res.data.data;
  }

  return null;
};

export const getApi = <T = any>(
  apiPath: string,
  config: AxiosRequestConfig = {},
  parser = parseResult
): Promise<T> => {
  return fetcher
    .get(apiPath, config)
    .then(parser)
    .catch((err) => {
      throw err;
    });
};

export const postApi = <T = any>(
  apiPath: string,
  param = {},
  config: AxiosRequestConfig = {}
): Promise<T> => {
  return fetcher
    .post(apiPath, param, config)
    .then(parseResult)
    .catch((err) => {
      throw err;
    });
};

export default fetcher;
