import BuildInfo from 'component/BuildInfo';
import MobileLogger from 'component/dev/MobileLogger';
import MapLayout from 'component/map/MapLayout';
import useWindowSize from 'hooks/useWindowSize';
import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import layoutStore from 'store/layoutStore';
import renderNestedRouter from 'utils/renderNestedRouter';

import routes from './routes';

const App = () => {
  const nowWindowSize = useWindowSize();

  useEffect(() => {
    layoutStore.windowResize({ width: nowWindowSize.width, height: nowWindowSize.height });

    if (nowWindowSize.isLandscape !== null) {
      layoutStore.setLandscape(nowWindowSize.isLandscape);
    }
  }, [nowWindowSize]);

  return (
    <>
      <Router>
        <MapLayout>{renderNestedRouter(routes)}</MapLayout>
      </Router>
      <BuildInfo />
      <MobileLogger />
    </>
  );
};

export default App;
