import classNames from 'classnames';
import { EDateFormat } from 'constant/Date';
import { useState } from 'react';
import { ReactComponent as IcoArrowDownSolid } from 'resource/images/icon_arrow_right_gray_16.svg';
import { TLog } from 'store/mobileLogStore';
import s from 'styles/components/dev/LogItem.module.scss';
import { getSafeDateFormat } from 'utils/date';

import ObjectViewer from './ObjectViewer';

type TProps = {
  log: TLog;
};

const LogApiBody = ({ log }: TProps) => {
  const [selectMenu, setSelectMenu] = useState<'request' | 'response'>('request');

  return (
    <div>
      <div className={s.select_tab}>
        <div
          className={classNames(s.tab_item, { [s.selected]: selectMenu === 'request' })}
          onClick={() => setSelectMenu('request')}
        >
          Request
        </div>
        <div
          className={classNames(s.tab_item, { [s.selected]: selectMenu === 'response' })}
          onClick={() => setSelectMenu('response')}
        >
          Response
        </div>
      </div>
      <div className={s.api_message_body}>
        <ObjectViewer object={(selectMenu === 'request' ? log.request : log.response) || {}} />
      </div>
    </div>
  );
};

const LogBody = ({ log }: TProps) => {
  if (log.message === undefined) {
    return null;
  }

  if (typeof log.message === 'string') {
    return <div>{log.message}</div>;
  }

  return (
    <div>
      <ObjectViewer object={log.message} />
    </div>
  );
};

const LogItem = ({ log }: TProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <li className={s.wrapper}>
      <div
        className={s.summary}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div>
          <div className={s.title}>{log.title}</div>
          <div className={s.timestamp}>
            {getSafeDateFormat(log.timestamp, EDateFormat.yyyyMMDDHHmmss)}
          </div>
        </div>
        <div className={s.guide}>
          <span className={s[log.type?.toLowerCase() || '']}>{log.type}</span>
          <IcoArrowDownSolid
            className={classNames(s.icon, {
              [s.rotate]: isOpen,
            })}
          />
        </div>
      </div>
      <div
        className={classNames(s.message, {
          [s.show]: isOpen,
        })}
      >
        {log.type === 'API' ? <LogApiBody log={log} /> : <LogBody log={log} />}
      </div>
    </li>
  );
};

export default LogItem;
