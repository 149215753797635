import { isProdEnv } from 'constant/Env';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import mobileLogStore from 'store/mobileLogStore';
import 'styles/index.scss';
import 'utils/datadog';
import { pageCaseMap } from 'utils/mock/msw';

import App from './App';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

async function enableMocking() {
  if (isProdEnv) {
    return;
  }

  const { worker } = await import('./tmsw/browser');
  const res = await import('./tmsw');
  new res.default(worker, pageCaseMap).start();
}

if (!isProdEnv) {
  mobileLogStore.addLog({
    type: 'DEB',
    title: 'serviceWorker 여부',
    message:
      'serviceWorker' in navigator === true ? '서비스워커 사용가능' : '서비스워커 사용불가능',
  });
}

enableMocking().then(() => {
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
});
