import BackButton from 'component/BackButton';
import Header from 'component/Header';
import TmdsChip from 'component/tmds/TmdsChip';
import TmdsSingleTextField from 'component/tmds/TmdsSingleTextField';
import { isProdEnv } from 'constant/Env';
import useHandleBackKey from 'hooks/useHandleBackKey';
import { useMemo, useState, useSyncExternalStore } from 'react';
import fetchLogStore, { TLogType } from 'store/mobileLogStore';
import s from 'styles/components/dev/MobileLogger.module.scss';

import LogList from './LogList';

type TFilter = {
  keyword?: string;
  type?: TLogType;
};

const logTypes: TLogType[] = ['API', 'DEB', 'ERR', 'MSG'];

const MobileLogger = () => {
  const logs = useSyncExternalStore(fetchLogStore.subscribe, fetchLogStore.getState);
  const [filter, setFilter] = useState<TFilter>({});
  const [show, setShow] = useState(false);

  const filteredLogs = useMemo(
    () =>
      logs
        .filter((log) => (filter.type ? filter.type === log.type : true))
        .filter((log) => (filter.keyword ? log.title.includes(filter.keyword) : true))
        .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime()),
    [filter, logs]
  );

  useHandleBackKey(show, () => {
    setShow(false);
  });

  if (isProdEnv) {
    return null;
  }

  return (
    <>
      {show && (
        <div className={s.wrapper}>
          <Header
            title="로그"
            leftButton={<BackButton onClick={() => setShow(false)} />}
          />

          <div className={s.filter_wrapper}>
            <TmdsSingleTextField
              value={filter.keyword}
              onChange={(e) => setFilter((prev) => ({ ...prev, keyword: e.target.value }))}
              label="검색"
            />
            <div className={s.select_wrapper}>
              <TmdsChip
                selected={filter.type === undefined}
                onClick={() => setFilter((prev) => ({ ...prev, type: undefined }))}
              >
                ALL
              </TmdsChip>
              {logTypes.map((logType) => (
                <TmdsChip
                  selected={logType === filter.type}
                  key={logType}
                  onClick={() => setFilter((prev) => ({ ...prev, type: logType }))}
                >
                  {logType}
                </TmdsChip>
              ))}
            </div>
          </div>

          <LogList logs={filteredLogs} />
        </div>
      )}
      <button
        className={s.log_open_btn}
        onClick={() => setShow((prev) => !prev)}
      >
        로그 열기
      </button>
    </>
  );
};

export default MobileLogger;
